var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "i-layout-sider-logo",
    class: {
      "i-layout-sider-logo-dark": _vm.siderTheme === "dark"
    },
    staticStyle: {
      height: "40px",
      "line-height": "40px"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-quick"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideLogo,
      expression: "!hideLogo"
    }],
    staticClass: "flex-align-center logo-wrap"
  }, [_c("img", {
    staticStyle: {
      width: "35px",
      height: "35px"
    },
    attrs: {
      src: require("@/assets/svg/logo.svg")
    }
  }), _c("span", {
    staticClass: "logo-title"
  }, [_vm._v("AMC分析系统")])])])], 1), _c("Menu", {
    ref: "menu",
    staticClass: "i-layout-menu-side i-scrollbar-hide",
    attrs: {
      theme: _vm.siderTheme,
      accordion: _vm.menuAccordion,
      "active-name": _vm.activePath,
      "open-names": _vm.openNames,
      width: "auto"
    }
  }, [_vm._l(_vm.filterSider, function (item, index) {
    return !_vm.menuCollapse ? [item.children == null || !item.children.length || item.children.every(function (i) {
      return i.hide;
    }) ? _c("i-menu-side-item", {
      key: index,
      attrs: {
        menu: item
      }
    }) : _c("i-menu-side-submenu", {
      key: index,
      attrs: {
        menu: item
      }
    })] : [item.children == null || !item.children.length || item.children.every(function (i) {
      return i.hide;
    }) ? _c("Tooltip", {
      key: index,
      attrs: {
        content: item.title,
        placement: "right"
      }
    }, [_c("i-menu-side-item", {
      attrs: {
        menu: item,
        "hide-title": ""
      }
    })], 1) : _c("i-menu-side-collapse", {
      key: index,
      attrs: {
        menu: item,
        "top-level": ""
      }
    })];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };