var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "img-c"
  }, [_c("img", {
    ref: "img",
    attrs: {
      src: _vm.imgSrc
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };