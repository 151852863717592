var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.isLimit ? _c("Breadcrumb", {
    ref: "breadcrumb",
    staticClass: "i-layout-header-breadcrumb"
  }, [_c("BreadcrumbItem", [_c("i-menu-head-title", {
    attrs: {
      item: _vm.siderMenuObject[_vm.activePath],
      "hide-icon": !_vm.showBreadcrumbIcon
    }
  })], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };