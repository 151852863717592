var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tooltip", {
    style: "max-width: ".concat(_vm.maxWidth, ",z-index:2000"),
    attrs: {
      placement: _vm.placement,
      transfer: _vm.transfer,
      theme: _vm.theme,
      "open-delay": _vm.delay
    }
  }, [_vm.$slots.content ? _c("template", {
    slot: "content"
  }, [_vm._t("content")], 2) : _c("template", {
    slot: "content"
  }, [_c("p", {
    style: "white-space:pre-wrap;max-width: ".concat(_vm.maxWidth, "px;line-height:18px")
  }, [_vm._v(_vm._s(_vm.content))])]), _vm.$slots.default ? [_vm._t("default")] : _vm._e(), _vm.showIcon ? _c("Icon", {
    staticClass: "icon",
    attrs: {
      type: _vm.icon,
      size: _vm.size,
      color: _vm.color
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };