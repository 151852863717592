import "core-js/modules/es6.array.find";
export default {
  name: 'advertiser-info',
  props: ['row', 'areaList', 'hideSite', 'noJumpId', 'isAsin', 'jumpToDetailsPath'],
  data: function data() {
    return {};
  },
  computed: {
    href: function href() {
      if (this.isAsin) {
        return "".concat(this.row.siteUrl, "sp?_encoding=UTF8&seller=").concat(this.row.sellerId, "&tab=&vasStoreID=");
      } else {
        return "".concat(this.getArea(this.row.areaId).dspUrl).concat(this.row.amazonEntityId, "/advertisers/").concat(this.row.advertiserIdStr, "/orders");
      }
    } // siteComputed(){
    // 	if(this.areaList){
    // 		let sites = this.areaList.reduce((pre,v)=>{
    // 			pre = [...v.sites,...pre];
    // 			return pre;
    // 		},[])
    // 		return sites.find(v=>v.id === this.row.siteId).name;
    // 	}else{
    // 		return '';
    // 	}
    // }
  },
  methods: {
    jumpToDetails: function jumpToDetails() {
      this.$router.push({
        name: this.jumpToDetailsPath,
        query: {
          id: this.row.id
        }
      });
    },
    getArea: function getArea(areaId) {
      // console.log(object);
      return this.areaList && this.areaList.find(function (i) {
        return i.id === areaId;
      }) || {};
    }
  }
};