import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import _slicedToArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
// 一些用来处理数据的方法，可能被用于表格或者其他地方实现数据的格式化
import util from '@/libs/util';
import dayjs from 'dayjs';
//********* 包括 浮点数保留两位小数，英式数值显示，非0转为0，百分比等 **********//
//非数字转换为0

export var nan2zero = function nan2zero(v) {
  return isNaN(v) || v == null ? 0 : Number(v);
};

//数字展示转换为n位小数
export var fixed2 = function fixed2(v) {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var fractionDigitsNum = Math.pow(10, fractionDigits);
  return (Math.round(nan2zero(v) * fractionDigitsNum) / fractionDigitsNum).toFixed(fractionDigits);
};
export var fixed2Ceil = function fixed2Ceil(v) {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var fractionDigitsNum = Math.pow(10, fractionDigits);
  return (Math.ceil(nan2zero(v) * fractionDigitsNum) / fractionDigitsNum).toFixed(fractionDigits);
};
//格式化数值 为 英式 格式
export var formatNumber = function formatNumber(v) {
  return "".concat(nan2zero(v)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//格式化数值为英式数值格式，并保持2位小数
export var formatFloatNumber = function formatFloatNumber(v) {
  return "".concat(fixed2(v)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//格式化数值为英式数值格式，并保持4位小数
export var formatFloatNumber4 = function formatFloatNumber4(v) {
  var data = fixed2(v, 4);
  var _data$split = data.split('.'),
    _data$split2 = _slicedToArray(_data$split, 2),
    integer = _data$split2[0],
    decimal = _data$split2[1];
  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimal;
};
export var formatMoney = function formatMoney(v) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  var data = fixed2(v);
  var _data$split3 = data.split('.'),
    _data$split4 = _slicedToArray(_data$split3, 2),
    integer = _data$split4[0],
    decimal = _data$split4[1];
  var u = unit ? unit : '';
  return u + integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimal;
};
export var formatMoney4 = function formatMoney4(v) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  var data = fixed2(v, 4);
  var _data$split5 = data.split('.'),
    _data$split6 = _slicedToArray(_data$split5, 2),
    integer = _data$split6[0],
    decimal = _data$split6[1];
  var u = unit ? unit : '';
  return u + integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimal;
};

//格式化数值 为 英式 货币 显示
// export const formatMoney = (v,unit='$') => `${ unit ? unit :'' } ${fixed2(v)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// export const formatMoney4 = (v,unit='$') => `${ unit ? unit :'' } ${fixed2(v,4)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// export const formatMoney = (v,unit='$') => `${ unit ? unit :'' }${fixed2(v)}`;
// export const formatMoney4 = (v,unit='$') => `${ unit ? unit :'' }${fixed2(v,4)}`;

//格式化数值为百分比
export var formatPercent = function formatPercent(v) {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return "".concat(fixed2(v * 100, fractionDigits), "%");
};
export var formatPercent4 = function formatPercent4(v) {
  var fractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  return "".concat(fixed2(v * 100, fractionDigits), "%");
};

//格式化输出时间
export var toDate = function toDate(date) {
  var includeTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var format = arguments.length > 2 ? arguments[2] : undefined;
  return date ? dayjs(new Date(date)).format(format ? format : includeTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD') : null;
};

//**************** 处理时间的 **************//

//**************** 设置页面标题 **************//
export var setTitle = function setTitle(title) {
  util.title({
    title: title
  });
};

//**************** 导出为excel **************//
export var exportAsExcel = function exportAsExcel(data, fileName, cb) {
  /*保存文件流*/
  var blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  });
  var url = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  if (fileName) link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  link = null;
  cb && cb instanceof Function && cb();
};
export var formatMillion = function formatMillion(value) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var num;
  if (value >= 1000000) {
    num = formatFloatNumber(Math.ceil(value / 10000) / 100) + ' M';
  }
  // else if (10000 <= value && value < 1000000) {
  // 	num = formatNumber((Math.floor(value / 100) / 100)) + ' W'
  // }
  else if (1000 <= value && value < 1000000) {
    num = formatFloatNumber(Math.ceil(value / 10) / 100) + ' K';
  } else {
    num = value;
  }
  return unit + num;
};

//获取范围区间
export function getNumSection(max, min, split) {
  var interval = parseInt(((max - min) / split).toString());
  var num = (interval + "").length;
  var double = 1;
  if (num <= 3) {
    for (var i = 1; i < num; i++) {
      double *= 10;
    }
  } else if (num > 3) {
    for (var _i = 1; _i < num - 1; _i++) {
      double *= 10;
    }
  }
  var setion = [Math.floor(min / double) * double];
  interval = Math.floor(interval / double) * double;
  for (var _i2 = 1; _i2 < split + 1; _i2++) {
    setion.push(Math.floor((min + _i2 * interval) / double) * double);
  }

  // return setion;
  return Array.from(new Set(setion));
}
//获取最大值/最小值
export function getMaxMin(data) {
  var max = 0;
  var min = 0;
  data.forEach(function (e, i) {
    max = e > max ? e : max;
    if (i === 0) {
      min = e;
    } else {
      min = e > min ? min : e;
    }
  });
  return {
    max: max,
    min: min
  };
}
// export function getMaxMin(data: number[]) {
// 	// 通过apply方法，可以让Math.max解析数组格式的数值集
// 	const max = Math.max.apply(Math,data);
// 	// 或者用解构
// 	//const max = Math.max(...data);
// 	// min同理
// 	const min= Math.min.apply(Math,data);
// 	//const min= Math.max(...data);
// 	return { max, min };
// }

/**
 * @param numArr-数值的数组
 * @param split-数值区间需要等分的份数
 * @param colors-图例对应的颜色区间
 * @param lastFlag-最后一项legend的展示方式，根据需求不同自定义三目运算符即可
 * @returns {*[]|*}
 */
export function getLegendData(numArr, split, colors, lastFlag) {
  var maxMin = getMaxMin(numArr); // 调用上面的方法
  var section = getNumSection(maxMin.max, maxMin.min, split); // 调用上面的方法
  if (section.length > 1) {
    return section.map(function (e, i) {
      if (i === section.length - 1) {
        return {
          label: lastFlag ? section[i] + "以上" : '>' + section[i],
          color: colors[i]
        };
      } else {
        return {
          label: section[i] + "-" + section[i + 1],
          color: colors[i]
        };
      }
    }); // .reverse()
  } else {
    return [];
  }
}

/**
 * @description 获取固定长度的随机字符串（数字类型字符串）
 * @param max 区间最大值
 * @param min 区间最小值
 * @return {String}
 */
export function maxgetRandomStr(min, max) {
  // 生成数字
  var random = String(Math.floor(Math.random() * (max - min + 1)) + min);
  // 补充前置 0
  var maxLength = max.length;
  for (var i = 0; i < max.length; i++) {
    random = '0' + random;
  }
  return random;
}