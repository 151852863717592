import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
import store from '@/store';
import axios from 'axios';
import util from '@/libs/util';
import Setting from '@/setting';
import qs from 'qs';
var isDev = process.env.NODE_ENV === 'development';
import { Message, Notice, Modal } from 'view-design';

// 创建一个错误
function errorCreate(msg) {
  var err = new Error(msg);
  errorLog(err);
  throw err;
}
// 取消请求相关
var pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
var CancelToken = axios.CancelToken;
var removePending = function removePending(config) {
  var url = config.url.split('?')[0];
  for (var p in pending) {
    if (pending[p].u === url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[p].f('aaaaaaaaaa'); // 执行取消操作
      pending.splice(p, 1); // 把这条记录从数组中移除
    }
  }
};
// 记录和显示错误
function errorLog(err) {
  // 添加到日志
  store.dispatch('admin/log/push', {
    message: '数据请求异常',
    type: 'error',
    meta: {
      error: err
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.error('>>>>>> Error >>>>>>');
    console.log(err);
  }
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    if (err.message === 'aaaaaaaaaa') {
      return;
    }
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    });
  }
}

// 创建一个 axios 实例
var service = axios.create({
  baseURL: Setting.apiBaseURL
  // timeout: 300000
});

/******token 校验出问题时 *****/
function tokenError(msg) {
  Modal.error({
    title: msg,
    content: "点击确定跳转登录页面",
    onOk: function onOk() {
      var url = Setting.loginURL + "?redirect=".concat(location.origin);
      location.href = url;
    }
  });
}

// 请求拦截器
service.interceptors.request.use(function (config) {
  var profiles = isDev ? 'dev' : 'prod';
  config.headers['profiles'] = profiles;
  //让每个请求携带token
  config.headers['token'] = util.cookies.get('token');
  config.headers['systemTypeId'] = 14;
  if (!config.isMore) {
    removePending(config); // 多个请求取消前面的请求
    var url = config.url.split('?')[0];
    config.cancelToken = new CancelToken(function (c) {
      pending.push({
        u: url + '&' + config.method,
        f: c
      });
    });
  }
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat'
      });
    };
  }
  return config;
}, function (error) {
  // 发送失败
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (response) {
  //如果有token错误代码就跳至登录页，没的话正常返回数据
  if (response.headers.tokenfaildcode) {
    tokenError("登录信息已失效");
  } else if (response.headers.SystemDenied) {
    tokenError(response.headers.SystemDeniedError);
  } else {
    var contentType = response.headers['content-type']; //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8
    if (contentType.includes('application/vnd.ms-excel') || contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;')) {
      console.log('导出', response);
      // 导出 需要把文件名返回出去
      // attachment;filename*=4KMILES-15989004592-US-zssaasmc-Standard%20Analysis%20Topic-AMC%20Report-202303166367.xlsx
      var fileName = response.headers['content-disposition'].split('=')[1];
      return {
        data: response.data,
        fileName: decodeURIComponent(fileName)
      };
    }
    return response.data;
  }
  return;
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权，请登录';
        break;
      case 403:
        error.message = '拒绝访问';
        break;
      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;
      case 408:
        error.message = '请求超时';
        break;
      case 500:
        error.message = '服务器内部错误';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '网关错误';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '网关超时';
        break;
      case 505:
        error.message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
});
export default service;