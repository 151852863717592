export default {
  path: '/amcop/amazon-audience',
  title: '人群包管理',
  children: [{
    path: "/amcop/amazon-audience/page-list",
    title: "人群包管理"
  }, {
    path: "/amcop/amazon-audience/edit",
    hide: true,
    title: "编辑人群包"
  }, {
    path: "/amcop/amazon-audience/create",
    hide: true,
    title: "创建人群包"
  }]
};