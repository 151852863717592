import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-page',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    total: {
      type: Number,
      dafult: 0
    },
    pageSizeOpts: {
      type: Array,
      default: function _default() {
        return [30, 50, 100];
      }
    },
    current: {
      type: Number,
      dafult: 0
    },
    pageSize: {
      type: Number,
      dafult: 50
    },
    simple: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    transfer: {
      type: Boolean,
      default: true
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    showElevator: {
      type: Boolean,
      default: true
    },
    showSizer: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    pageChange: function pageChange(pageNum) {
      this.$emit('update:current', pageNum);
      this.$emit('on-change', pageNum);
    },
    pageSizeChange: function pageSizeChange(pageSize) {
      this.$emit('update:pageSize', pageSize);
      this.$emit('on-page-size-change', pageSize);
    }
  }
};