var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "i-layout-tabs ivu-clearfix",
    class: _vm.classes,
    style: _vm.styles
  }, [_vm.aaaaaa ? _c("div", {
    staticClass: "i-layout-tabs-main"
  }, [_c("Tabs", {
    attrs: {
      type: "card",
      value: _vm.current,
      animated: false,
      draggable: true,
      "before-remove": _vm.beforeRemove
    },
    on: {
      "on-click": _vm.handleClickTab,
      "on-tab-remove": _vm.handleClickClose,
      "on-drag-drop": _vm.handleDragDrop
    }
  }, _vm._l(_vm.opened, function (page, i) {
    return _c("TabPane", {
      key: i,
      attrs: {
        closable: page.path !== "/home",
        label: function label(h) {
          return _vm.tabLabel(h, page);
        },
        name: page.fullPath
      }
    });
  }), 1), _c("Dropdown", {
    staticClass: "ml-10",
    attrs: {
      placement: "bottom-end"
    },
    on: {
      "on-click": _vm.handleClose
    }
  }, [_c("div", [_c("Icon", {
    attrs: {
      type: "ios-arrow-down"
    }
  })], 1), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_c("DropdownItem", {
    attrs: {
      name: "left"
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-arrow-back"
    }
  }), _vm._v("\n                        关闭左侧\n                    ")], 1), _c("DropdownItem", {
    attrs: {
      name: "right"
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-arrow-forward"
    }
  }), _vm._v("\n                        关闭右侧\n                    ")], 1), _c("DropdownItem", {
    attrs: {
      name: "other"
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-close"
    }
  }), _vm._v("\n                        关闭其他\n                    ")], 1), _c("DropdownItem", {
    attrs: {
      name: "all"
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-close-circle"
    }
  }), _vm._v("\n                        全部关闭\n                    ")], 1)], 1)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };