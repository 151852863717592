export default {
  name: 'fk-export-data',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    ghost: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    customIcon: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};