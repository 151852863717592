import echartIcon from "../../../assets/svg/echart_icon.svg";
import echartIconAct from "../../../assets/svg/echart_icon_act.svg";
import tableIcon from "../../../assets/svg/table_icon.svg";
import tableIconAct from "../../../assets/svg/table_icon_act.svg";
export default {
  name: "fk-data-swich",
  props: {
    value: {
      type: String,
      default: 'echart'
    }
  },
  data: function data() {
    return {
      btnList: [{
        type: 'echart',
        icon: echartIcon,
        actIcon: echartIconAct
      }, {
        type: 'table',
        icon: tableIcon,
        actIcon: tableIconAct
      }],
      activeBtn: 'echart'
    };
  },
  methods: {
    onClick: function onClick(type) {
      this.activeBtn = type;
      this.$emit('input', type);
      this.$emit('on-chang', type);
    }
  }
};