import request from "@/plugins/request";

// 获取所有未读消息数量
export var getNotReadCount = function getNotReadCount() {
  return request.get("/systemNotice/notReadCount");
};

// 获取最新的未读公告
export var getNewNotice = function getNewNotice() {
  return request.get("/systemNotice/last");
};

// 清除所有系统消息
export var clearSystemNotReadCount = function clearSystemNotReadCount(filter) {
  return request.put("/systemNotice/clear", filter, {
    isMore: true
  });
};

// 搜索所有员工
export var searchWxUser = function searchWxUser(params) {
  return request.post("/system/search/wxUser", params);
};

// 获取站点
export var getSiteList = function getSiteList() {
  return request.get("/common/selectable/area/site");
};

// 操作日志
export var getLogList = function getLogList(params) {
  return request.put("/common/log", params);
};

// 操作日志下拉
export var getLogPrepare = function getLogPrepare() {
  return request.get("/common/log/prepare");
};
export var getSiteIcon = function getSiteIcon() {
  return request.get("/common/search/site/all");
};