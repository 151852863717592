export default {
  path: '/amcop/model-management',
  title: '模型管理',
  children: [{
    path: "/amcop/model-management/page-list",
    title: "模型管理",
    children: [{
      path: "/amcop/model-management/edit",
      hide: true,
      title: "编辑模型"
    }
    // {
    //     path: "/amcop/model-management/create",
    //     hide: true,
    //     title: "创建模型",
    // },
    ]
  }, {
    path: "/amcop/model-management/classification",
    title: "模型分类管理"
  }]
};