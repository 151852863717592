export default {
  title: '客户管理',
  path: '/amcop/customer-management',
  children: [{
    path: "/amcop/customer-management/page-list",
    title: "客户管理"
  }, {
    path: "/amcop/customer-management/detail",
    hide: true,
    title: "客户详情"
  }, {
    path: "/amcop/customer-management/mapping-table",
    hide: true,
    title: "映射表"
  },
  // {
  //     path: "/amcop/customer-management/topic-list",
  //     hide: true,
  //     title: "专题列表",
  // },
  // {
  //     path: "/amcop/customer-management/topic-list/edit",
  //     hide: true,
  //     title: "编辑专题",
  // },
  {
    path: "/amcop/customer-management/topic-list/execution-records",
    hide: true,
    title: "执行记录"
  }, {
    path: "/amcop/customer-management/topic-list/edit-report",
    hide: true,
    title: "编辑报告"
  }, {
    path: "/amcop/customer-management/topic-list/preview-report",
    hide: true,
    title: "预览报告"
  }]
};