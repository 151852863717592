import "core-js/modules/es6.number.constructor";
/**
 * @description 用于echart多数据模型下拉筛选，主要特性如下：
 *      1.自定义颜色
 *      2.对echarts图表的显示隐藏控制（小眼睛图标）
 *      3.可选的问号tip内容
 *      4.单选
 *  @author zhangkai
 */
export default {
  name: 'fk-select-echarts',
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: function _default() {
        return [];
      }
    },
    maxTagCount: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    optionList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    filterable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    transfer: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // 颜色
    color: {
      type: String,
      default: "#666666"
    },
    width: {
      type: String,
      default: "150px"
    },
    // 是否显示tip
    tipShow: {
      type: Boolean,
      default: false
    },
    // tip内容
    tipContent: {
      type: [String, Number],
      default: ''
    },
    // 是否显示小眼睛
    showEye: {
      type: Boolean,
      default: false
    },
    // 小眼睛
    eyeSwich: {
      type: Boolean,
      default: true
    },
    // 图标
    icon: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: function value(_value) {
      this.selectValue = _value;
    }
  },
  data: function data() {
    return {
      selectValue: this.value || null
    };
  },
  methods: {
    onChange: function onChange(value) {
      this.$emit('input', value);
      this.$emit("on-change", value);
    },
    onSelect: function onSelect(value) {
      this.$emit("on-select", value);
    },
    onClear: function onClear() {
      this.$emit('input', null);
      this.$emit("on-change", null);
    },
    // 小眼睛控制
    eyeSwichChange: function eyeSwichChange(value) {
      this.$emit('eye-swich-change', value);
    }
  }
};