import SlsWebLogger from 'js-sls-logger';
var opts = {
  host: 'cn-shanghai.log.aliyuncs.com',
  project: 'user-activity',
  logstore: 'amcop-visit',
  time: 10,
  count: 20
};
var isDev = process.env.NODE_ENV === "development";
var logger = new SlsWebLogger(opts);
export default (function (route, userInfo) {
  try {
    // console.log('js-sls-logger')
    if (userInfo) {
      var info = {
        path: route.fullPath,
        title: formatTitle(route.meta.title),
        department: userInfo.departments || '',
        userAccount: userInfo.email,
        userId: userInfo.id,
        userName: userInfo.wxName
      };
      if (location.host.indexOf('test') > -1 || isDev) info.isTest = 1;
      logger && logger.send(info);
    }
  } catch (e) {
    console.log(e);
  }
});
var formatTitle = function formatTitle(title) {
  if (title === '客户管理') return '客户管理';
  if (title === '客户详情') return '客户管理-客户详情';
  // if (title === '专题列表') return '客户管理-专题列表'
  // if (title === '编辑专题') return '客户管理-专题列表-编辑专题'
  if (title === '执行记录') return '客户管理-执行记录';
  if (title === '编辑报告') return '客户管理-编辑报告';
  if (title === '映射表') return '客户管理-映射表';
  if (title === '人群包管理') return '人群包管理';
  if (title === '创建人群包') return '人群包管理-创建人群包';
  if (title === '编辑人群包') return '人群包管理-编辑人群包';
  if (title === '模型管理') return '模型管理';
  if (title === '编辑模型') return '模型管理-编辑模型';
  if (title === '模型分类管理') return '模型分类管理';
  return title;
};