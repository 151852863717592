var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Button", {
    staticClass: "btn-box",
    attrs: {
      loading: _vm.loading,
      type: _vm.type,
      ghost: _vm.ghost,
      icon: _vm.icon,
      "custom-icon": _vm.customIcon
    },
    on: {
      click: function click($event) {
        return _vm.$emit("on-click");
      }
    }
  }, [_vm.$slots.default ? [_vm._t("default")] : _c("div", {
    staticClass: "fk-export-download"
  }, [_c("span", {
    staticClass: "el-import-icon-xiazai2x"
  })])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };