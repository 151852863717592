export default {
  name: 'err-img',
  props: {
    src: {
      default: 'default.png'
    },
    err: {
      default: require('@/assets/images/default.png')
    }
  },
  data: function data() {
    return {
      errorImg: "this.src='".concat(this.err, "'")
    };
  },
  computed: {
    imgSrc: function imgSrc() {
      return this.src ? this.src : this.err;
    }
  }
};