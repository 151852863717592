var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ButtonGroup", {
    staticStyle: {
      width: "50px"
    }
  }, [_vm._l(_vm.btnList, function (btn) {
    return [_c("Button", {
      key: btn.type,
      attrs: {
        type: _vm.value === btn.type ? "primary" : "default"
      },
      on: {
        click: function click($event) {
          return _vm.onClick(btn.type);
        }
      }
    }, [_c("div", {
      staticClass: "icon-box"
    }, [_vm.value === btn.type ? _c("img", {
      attrs: {
        src: btn.actIcon,
        height: "16",
        width: "16",
        alt: ""
      }
    }) : _c("img", {
      attrs: {
        src: btn.icon,
        height: "16",
        width: "16",
        alt: ""
      }
    })])])];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };