import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import { throttle } from "lodash";
export default {
  name: "dsp-multiple",
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    maxTagCount: {
      type: Number,
      default: 2
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    optionList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    filterable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    transfer: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    showAllSelect: {
      type: Boolean,
      default: true
    },
    showButton: {
      type: Boolean,
      default: false
    },
    maxSelectCount: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      selectOptions: this.value || [],
      isShow: false,
      showFalseCancle: true
    };
  },
  computed: {
    // selectOptions使用这个可以触发 all的更新
    all: function all(_ref) {
      var $slots = _ref.$slots,
        optionList = _ref.optionList,
        selectOptions = _ref.selectOptions;
      var list;
      if (optionList && optionList.length > 0) {
        list = optionList.map(function (v) {
          return v.id;
        });
      } else if ($slots.default && $slots.default.length > 0) {
        list = $slots.default.map(function (v) {
          return v.componentOptions.propsData.value;
        });
      }
      return list || [];
    }
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.selectOptions = newVal;
    },
    isShow: function isShow(newVal) {
      var _this = this;
      if (newVal === true) {
        this.showFalseCancle = false;
      }
      if (newVal === false) {
        setTimeout(function () {
          _this.showFalseCancle = true;
        }, 0);
      }
    },
    optionList: function optionList(newVal, oldVal) {
      if (!newVal) return;
      if (newVal.length !== oldVal.length && this.selectOptions.includes(-5)) {
        this.selectOptions = [-5].concat(_toConsumableArray(this.all));
      }
    }
  },
  created: function created() {
    // console.log(this.$slots, this.optionList);
  },
  mounted: function mounted() {},
  methods: {
    clear: function clear() {
      this.selectOptions = [];
      this.$emit("input", this.selectOptions);
      this.$emit("on-change", this.selectOptions);
    },
    selectAll: function selectAll() {
      if (this.selectOptions.length < this.all.length) {
        this.selectOptions = [-5].concat(_toConsumableArray(this.all));
      } else {
        this.selectOptions = [];
      }
      // this.$emit("on-change", this.selectOptions);
      // if(!this.showButton){
      //     this.$emit("input", this.selectOptions);
      // }
    },
    // 下拉选项改变触发
    change: function change(val) {
      var _this2 = this;
      if (!val.includes(-5) && val.length === this.all.length) {
        this.selectOptions.unshift(-5);
      } else if (val.includes(-5) && val.length - 1 < this.all.length) {
        this.selectOptions = this.selectOptions.filter(function (item) {
          return item !== -5;
        });
      } else if (this.maxSelectCount > 0) {
        //最大选择量限制
        if (val.length > this.maxSelectCount) {
          this.selectOptions = this.selectOptions.filter(function (item, index) {
            return index < _this2.maxSelectCount;
          });
        }
      }
      this.$emit("on-change", this.selectOptions);
      if (!this.showButton) {
        this.$emit("input", this.selectOptions);
      }
      // if (this.showFalseCancle) {
      //   this.$emit("input", this.selectOptions);
      //   this.$emit("on-change",this.selectOptions);
      // }
    },
    // 下拉框显示隐藏时触发  下拉框关闭时，执行取消事件
    openChange: function openChange(e) {
      this.isShow = e;
      if (e === false) {
        this.canale();
      }
    },
    ok: function ok() {
      document.body.click();
      this.$emit("input", this.selectOptions);
      this.$emit("on-change", this.selectOptions);
    },
    canale: function canale() {
      if (this.value && this.value.length > 0) {
        this.$emit("input", this.value);
        this.canaleEvent(this.value);
      } else {
        this.$emit("input", []);
        this.canaleEvent([]);
      }
    },
    canaleEvent: function canaleEvent(arr) {
      this.selectOptions = arr;
      document.body.click();
    }
  }
};